import React from 'react';
import {
  NotFoundSection,
  NotFoundSectionInner,
  NotFoundHeading,
  NotFoundPara,
  NotFoundBtnBg,
} from './404.style';
import { CommonBtn } from './common.style';

const NotFound = () => (
  <NotFoundSection>
    <NotFoundSectionInner>
      <NotFoundHeading>A keresett oldal nem található.</NotFoundHeading>
      <NotFoundPara>
        Olyan oldalra tévedtél, ami valójában nincs. Hogy ez a mi hibánk, vagy a tiéd, azt nem
        tudjuk, mindenesetre biztosan nem itt van, amit keresel.
      </NotFoundPara>
      <NotFoundBtnBg>
        <CommonBtn href="https://www.doboktatas.hu">Vissza a kezdőlapra</CommonBtn>
      </NotFoundBtnBg>
    </NotFoundSectionInner>
  </NotFoundSection>
);

export default NotFound;
